var site = site || {};

site.direction = site.direction || {};

(function ($) {
  Drupal.behaviors.toutCarouselFormatterV1 = {
    attach: function (context) {
      var $template = $('.js-tout-carousel-formatter-v1', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $template.each(function () {
        var $self = $(this);
        var $carousel = $self.find('.js-tout-carousel');
        var $arrowsDiv = $self.find('.js-carousel-controls');
        var $dotsDiv = $self.find('.js-carousel-dots');
        var toutSlide = '.js-tout-carousel__slide';
        var $toutSliders = $self.find(toutSlide);
        var defaultSlideDisplayInterval = 6000;
        var defaultSlideTransitionTime = 300;
        // autoplay settings
        var autoplay = $carousel.attr('data-slides-autoplay');
        var slideDisplayInterval = parseInt($carousel.data('slides-speed'));
        var slideTransitionTime = parseInt($carousel.data('slides-speedplay'));

        if (typeof autoplay === 'undefined') {
          autoplay = false;
        } else {
          autoplay = true;
        }
        if (isNaN(slideDisplayInterval)) {
          slideDisplayInterval = defaultSlideDisplayInterval;
        }
        if (isNaN(slideTransitionTime)) {
          slideTransitionTime = defaultSlideTransitionTime;
        }
        var settings = {
          rtl: site.direction.isRTL,
          arrows: true,
          appendArrows: $arrowsDiv,
          dots: true,
          appendDots: $dotsDiv,
          slide: toutSlide,
          fade: $self.data('fade') && $self.data('fade') === true,
          infinite: false,
          slidesToShow: $self.data('slides-large'),
          slidesToScroll: $self.data('slides-large'),
          autoplay: autoplay,
          autoplaySpeed: slideDisplayInterval,
          speed: slideTransitionTime,
          adaptiveHeight: false,
          pauseOnFocus: true,
          onSetPosition: function (_slick) {
            _slick.$slider.trigger('afterSetPositionDots', _slick);
            $toutSliders.removeClass('slick-active-first');
            $toutSliders.filter('.slick-active').eq(0).addClass('slick-active-first');
          },
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                adaptiveHeight: false,
                slidesToShow: $self.data('slides-medium'),
                slidesToScroll: $self.data('slides-medium')
              }
            },
            {
              breakpoint: 768,
              settings: {
                adaptiveHeight: false,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        if ($self.hasClass('slick-initialized')) {
          try {
            $self.slick('destroy');
          } catch (e) {
            console.log(e);
          }
        }
        $self.on('afterSetPositionDots', function (e, _slick) {
          var $mediaContentBlock = $carousel.find('.js-content-block-media');
          var position = 0;
        });
        // Init this carousel with our settings
        $carousel.slick(settings);
      });
    }
  };
})(jQuery);
